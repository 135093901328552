<template>
  <div class="calendar-container container-fluid panel">
    <header>
      <div class="row month-changer">
        <div class="col-2">
          <div class="col-12" @click="prev">
            <font-awesome-icon icon="chevron-left" class="float-left" />
          </div>
        </div>
        <div class="col month p-0">{{ months[month] }} {{ year }}</div>
        <div key="next-button" class="col-2" @click="next">
          <font-awesome-icon icon="chevron-right" class="float-right" />
        </div>
      </div>
    </header>
    <div class="body d-flex">
      <div v-show="isBusy || !dates" class="mt-2 p-2 col-12">
        <b-spinner type="grow" label="Ładowanie..." class="spinner"></b-spinner>
      </div>
      <div class="scroll-container d-flex">
        <div
          v-bind:key="dates.indexOf(date)"
          v-for="date in dates"
          class="day-col block col-2 day-item"
          :class="{ 'animated fadeIn': !isBusy }"
        >
          <div hidden>{{ date }}</div>
          <div
            class="circle d-flex align-items-center align-content-center"
            :class="{ active: date.active }"
            :ref="date.active ? 'active' : undefined"
          >
            <div @click="selectDate(date)" class="row">
              <div class="day col-12">{{ new Date(date.date) | moment('dd') }}</div>
              <div class="number col-12">
                <div>{{ date.day }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import momentjs from 'moment'
import axios from 'axios'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Calendar',
  computed: {
    moment() {
      return momentjs
    }
  },
  async mounted() {
    await this.restoreMonth()
    await this.fetchAvailableVisits()
  },
  data() {
    return {
      isBusy: false,
      selected: {},
      minMonth: new Date().getUTCMonth(),
      minYear: new Date().getUTCFullYear(),
      year: new Date().getUTCFullYear(),
      month: new Date().getUTCMonth(),
      dates: [],
      months: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień'
      ],
      days: ['Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob', 'Ndz'],
      updateTimeout: '',
      selectFirstAvailableTimeout: '',
      nextWasClicked: false,
      prevWasClicked: false,
      visitsTotal: 0,
      cancelTokenSource: null
    }
  },
  watch: {
    selected() {
      this.$db.setItem('clinic_calendar_selected_day', this.selected)
    },
    '$store.state.calendarNeedsUpdate': function (value) {
      const refreshNeed = value
      if (refreshNeed.updateNeeded && refreshNeed.refreshNow) {
        this.fetchAvailableVisits()
        this.$store.commit('calendarNeedToBeRerender', { updateNeeded: false, refreshNow: false })
      }
    }
  },
  methods: {
    scrollToElement() {
      const [elem] = this.$refs.active
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth', inline: 'center' })
      }
    },
    next() {
      const vm = this
      vm.dates = [[]]
      this.$db.removeItem('clinic_calendar_selected_day')
      this.$emit('purge')
      // eslint-disable-next-line default-case
      if (vm.month === 11) {
        vm.month = 0
        // eslint-disable-next-line no-plusplus
        vm.year++
      } else {
        // eslint-disable-next-line no-plusplus
        vm.month++
      }
      vm.nextWasClicked = true
      this.update()
    },
    prev() {
      const vm = this
      vm.dates = [[]]
      this.$db.removeItem('clinic_calendar_selected_day')
      this.$emit('purge')
      // eslint-disable-next-line default-case
      if (vm.month === 0) {
        vm.month = 11
        // eslint-disable-next-line no-plusplus
        vm.year--
      } else {
        // eslint-disable-next-line no-plusplus
        vm.month--
      }
      vm.prevWasClicked = true
      this.update()
    },
    async setFirstDayOfMonth(year, month) {
      const firstDayOfMonth = new Date(year, month, 1).getDate()
      const date = this.dates.find((d) => d.day === firstDayOfMonth)
      await this.$db.setItem('clinic_calendar_selected_day', date)
    },
    async setLastDayOfMonth(year, month) {
      const lastDayOfMonth = new Date(year, month, 0).getDate()
      const date = this.dates.find((d) => d.day === lastDayOfMonth)
      await this.$db.setItem('clinic_calendar_selected_day', date)
    },
    update() {
      if (this.cancelTokenSource !== null) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }
      this.fetchAvailableVisits()
    },
    async getNearestDate(dateToSearch, dates) {
      const dateToCheck = momentjs(dateToSearch)
      let nearestDate = null
      let smallestDiff = Infinity
      dates.forEach((date) => {
        const diffWithDate = Math.abs(dateToCheck.diff(momentjs(date.date), 'days'))
        if (Math.abs(diffWithDate) < smallestDiff) {
          smallestDiff = Math.abs(diffWithDate)
          nearestDate = date
        }
      })
      return nearestDate
    },
    async selectFirstAvailableOrRestore() {
      const vm = this
      const day = await this.$db.getItem('clinic_calendar_selected_day')
      if (day) {
        this.selectDate(day)
      } else {
        vm.selectFirstAvailableTimeout = setTimeout(async () => {
          if (vm.dates.length !== 0) {
            const nearestDate = await vm.getNearestDate(new Date(), vm.dates)
            vm.selectDate(nearestDate)
            await this.$db.setItem('clinic_calendar_selected_day', nearestDate)
          } else {
            vm.selectDate(undefined)
          }
        }, 0)
      }
    },
    selectDate(selectedDate) {
      const vm = this
      this.$emit('purge')
      // eslint-disable-next-line no-restricted-syntax
      for (const date of vm.dates) {
        date.active = date.date === selectedDate.date
      }
      vm.selected = selectedDate
      this.$emit('dateChanged', selectedDate)
    },
    async fetchDoctorDays(dateFrom, dateTo) {
      const user = await this.$db.getItem('user')
      const input = {
        doctor_id: user.unison_id,
        date_range: [dateFrom, dateTo]
      }
      return this.$http
        .post('/e-zacma/doctor/visits/days', input, {
          cancelToken: this.cancelTokenSource.token
        })
        .catch((error) => {
          // eslint-disable-next-line no-underscore-dangle
          if (axios.isCancel(error)) {
            return { data: undefined }
          }
          return { data: [] }
        })
    },
    enumerateDaysBetweenDates(startDate, endDate) {
      const dates = []

      function formatDate(date) {
        const d = new Date(date)
        let month = `${d.getMonth() + 1}`
        let day = `${d.getDate()}`
        const year = d.getFullYear()

        if (month.length < 2) month = `0${month}`
        if (day.length < 2) day = `0${day}`

        return [year, month, day].join('-')
      }

      const currDate = momentjs(startDate).startOf('day')
      const lastDate = momentjs(endDate).startOf('day').add(1, 'days')

      do {
        dates.push(formatDate(new Date(currDate.clone().toDate())))
      } while (currDate.add(1, 'days').diff(lastDate) < 0)

      return dates
    },
    async fetchAvailableVisits() {
      this.isBusy = true
      const vm = this
      vm.dates = [[]]
      vm.visitsTotal = 0
      const month = vm.month + 1
      const { year } = vm
      const dateFrom = `${year}-${month > 9 ? month : `0${month}`}-01`
      const daysInMonth = momentjs(dateFrom, 'YYYY-MM-DD').daysInMonth()
      const dateTo = `${year}-${month > 9 ? month : `0${month}`}-${daysInMonth}`

      const dates = this.enumerateDaysBetweenDates(dateFrom, dateTo)

      const cancelTokenIsNull = this.cancelTokenSource === null
      this.cancelTokenSource = cancelTokenIsNull
        ? axios.CancelToken.source()
        : this.cancelTokenSource

      vm.dates = dates.map((date) => ({
        date,
        day: +momentjs(date, 'YYYY-MM-DD').format('D'),
        active: false
      }))

      if (vm.nextWasClicked) {
        await vm.setFirstDayOfMonth(year, month)
        vm.nextWasClicked = false
      } else if (vm.prevWasClicked) {
        await vm.setLastDayOfMonth(year, month)
        vm.prevWasClicked = false
      }

      await this.selectFirstAvailableOrRestore()
      this.isBusy = false
      if (vm.dates.length > 0) {
        setTimeout(async () => {
          vm.scrollToElement()
        }, 300)
      }
    },
    async restoreSelectedDay() {
      const day = await this.$db.getItem('clinic_calendar_selected_day')
      if (day) {
        this.selectDate(day)
      } else {
        await this.$db.setItem('clinic_calendar_selected_day', this.selected)
      }
    },
    async restoreMonth() {
      const day = await this.$db.getItem('clinic_calendar_selected_day')
      if (day) {
        this.year = +momentjs(day.date, 'YYYY-MM-DD').format('YYYY')
        this.month = +momentjs(day.date, 'YYYY-MM-DD').format('MM') - 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
.empty {
  text-align: center;
}

.spinner {
  display: block;
  margin: 0 auto;
}

.visit-count-badge {
  margin-top: -7px;
  font-size: 15px;
  line-height: 25px;
}

.day-item {
  height: 80px;
  align-items: center;
  display: flex;
}

.visits-number {
  width: 20px;
  height: 20px !important;
  background: linear-gradient(to top, #e6e6e6, #ffffff) !important;
  font-weight: 600 !important;
  color: black !important;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important;
  position: absolute;
  right: 8px;
  top: 0;
}
</style>
