const ErrorHandler = {
  install(Vue, options = {}) {
    Vue.prototype.$handleLaravelErrorResponse = function (e) {
      if (e.response === undefined) {
        return
      }
      const responseData = e.response.data
      const message = []
      const errors = typeof responseData.errors === 'undefined' ? {} : responseData.errors
      // eslint-disable-next-line no-restricted-syntax
      for (const error of Object.values(errors)) {
        message.push(error[0])
      }

      const isWrongNumberOfSegments = responseData?.message?.includes('Wrong number of segments')
      const isTokenInvalid = responseData?.error?.includes('Token is invalid')

      if (!isWrongNumberOfSegments && !isTokenInvalid) {
        Vue.prototype.$toast.error({
          title: responseData.message || 'Wystąpił błąd',
          message: message?.join('<br>') || 'Błąd podczas przetwarzania żądania'
        })
      }
    }

    Vue.prototype.$error = function (message = null, title = null) {
      Vue.prototype.$toast.error({
        title: title || 'Wystapił błąd',
        message: message || 'Błąd podczas przetwarzania żądania',
        position: 'bottomCenter',
        overlayColor: 'rgba(0, 0, 0)',
        color: '#ff5c67',
        layout: 2,
        titleSize: 20,
        messageSize: 20,
        messageLineHeight: 30,
        titleLineHeight: 30
      })
    }

    Vue.prototype.$success = function (message = null, title = null) {
      Vue.prototype.$toast.success({
        title: title || 'Sukces',
        message: message || 'Operacja zakończyła się sukcesem',
        position: 'bottomCenter',
        overlayColor: 'rgba(0, 0, 0)',
        color: '#a4efb7',
        layout: 2,
        titleSize: 20,
        messageSize: 20,
        messageLineHeight: 30,
        titleLineHeight: 30
      })
    }
  }
}

export default ErrorHandler.install
